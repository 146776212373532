import { WizardForm } from 'views/BookPage/types'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

type WizardFormStore = {
  wizardForm: WizardForm
  updateWizardForm: (update: Partial<WizardForm>) => void
  resetWizardForm: (placeId?: string) => void
}

const initialState: WizardForm = {
  placeId: '',
  serviceCategoryId: undefined,
  serviceId: undefined,
  serviceName: undefined,
  queueId: undefined,
  scheduleId: undefined,
  privacyPolicy: false,
  termsAndConditions: false,
  notificationStatus: undefined,
  queueType: undefined,
  order: undefined,
  contactData: {
    firstName: undefined,
    lastName: undefined,
    email: undefined,
    phone: undefined,
    numberOfVisitors: 1,
    pushToken: undefined,
  },
}

export const useWizardForm = create<WizardFormStore>()(
  persist(
    (set, get) => ({
      wizardForm: { ...initialState },
      updateWizardForm: (update: Partial<WizardForm>) => {
        set(state => {
          const updatedWizardForm = {
            wizardForm: {
              ...state.wizardForm,
              ...update,
            },
          }
          return updatedWizardForm
        })
      },
      resetWizardForm: (placeId?: string) => {
        set({ wizardForm: { ...initialState, placeId } })
      },
    }),
    {
      name: '2meters-wizard-form',
    }
  )
)
