'use client'
import { Alert } from 'components/Alert'
import { Layout } from 'components/Layout'
import UINotifications from 'components/UINotifications'
import { Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'
import { BookPage } from 'views/BookPage'
import { KioskPage } from 'views/KioskPage'
import { MainPage } from 'views/MainPage'
import { NoFoundPage } from 'views/NoFoundPage'
import { PlacePage } from 'views/PlacePage'
import { ScanQrPage } from 'views/ScanQrPage'
import { SignInPage } from 'views/SignInPage'
import { TicketPage } from 'views/TicketPage'
import { TicketsPage } from 'views/TicketsPage'
import { LanguageProvider } from './LanguageProvider'
import { Router } from './Router'
import { WithAnonymousAuthentication, WithPlace } from './auth'
import * as ROUTES from './routes'

const CHECKPOINT_DOMAIN = 'book.checkpoint.ph'
const CHECKPOINT_REDIRECT = '/places/rEW5O6OxQopqDNjnFPr1'

export const App = () => {
  // Redirect to theCHECKPOINT place for CHECKPOINT_DOMAIN
  const hostname = typeof window !== 'undefined' ? window.location.hostname : ''
  const path = typeof window !== 'undefined' ? window.location.pathname : ''
  if (hostname.includes(CHECKPOINT_DOMAIN) && path === '/') {
    window.location.href = CHECKPOINT_REDIRECT
    return null // Prevent rendering anything else
  }

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <LanguageProvider>
        <Router>
          <Alert />
          <UINotifications />
          <Routes>
            <Route path='' element={<WithAnonymousAuthentication />}>
              <Route path='' element={<WithPlace />}>
                <Route path='' element={<Layout />}>
                  <Route path={ROUTES.SCANNER} element={<SignInPage />} />
                  <Route path={ROUTES.VISITOR.MAIN} element={<MainPage />} />
                  <Route path={ROUTES.VISITOR.SCAN_QR} element={<ScanQrPage />} />
                  <Route path={ROUTES.VISITOR.BOOK} element={<BookPage />} />
                  {/* <Route path={ROUTES.VISITOR.KIOSK_BOOK} element={<BookPage />} /> */}
                  <Route path={ROUTES.VISITOR.TICKETS} element={<TicketsPage />} />
                  <Route path={ROUTES.VISITOR.TICKET} element={<TicketPage />} />
                  <Route path={ROUTES.VISITOR.KIOSK} element={<KioskPage />} />
                  <Route path={ROUTES.VISITOR.PLACE} element={<PlacePage />} />
                </Route>
              </Route>
            </Route>
            {/* Handling 404 */}
            <Route path='*' element={<NoFoundPage />} />
          </Routes>
        </Router>
      </LanguageProvider>
    </Suspense>
  )
}
