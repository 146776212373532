import { Container } from 'components/Container'
import { SERVICE_STEP, useWizardSteps } from 'views/BookPage/useWizardSteps'
import { useEffect, useState } from 'react'

export const ProgressBar = () => {
  const { totalSteps, activeStepIndex, isFirstStep, activeStep } = useWizardSteps()
  const [percent, setPercent] = useState('0')

  useEffect(() => {
    if (!isFirstStep) {
      if (activeStep === 'choice') {
        setPercent('25')
      } else {
        setPercent(`${((activeStepIndex / totalSteps) * 100).toFixed(0)}`)
      }
    }
    if (isFirstStep) {
      setPercent('0')
    }
    if (activeStep === SERVICE_STEP) {
      setPercent('10')
    }
  }, [totalSteps, activeStepIndex])

  return (
    <div className='w-full bg-custom-bg'>
      <div className='hidden md:block'>
        <Container>
          <div style={{ width: `${percent}%` }} className={`bg-custom-main h-[4px]`}></div>{' '}
        </Container>
      </div>

      <div style={{ width: `${percent}%` }} className={`block md:hidden bg-custom-main h-[4px]`} />
    </div>
  )
}
