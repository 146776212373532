import { FormField, TOGGLE_CHECKED } from '@2meters/types'
import { RadioGroup } from '@headlessui/react'
import { Input } from 'components/Form'
import { RichTextParse } from 'components/RichTextParse'
import { useState } from 'react'
import { useTranslation } from 'utils/useTranslation'
import { CheckboxGroup, CheckboxOption } from '../../../components/CheckboxGroup'
import { WizardFormField } from '../types'
import { SelectablePaper } from './SelectablePaper'

interface FormFieldProps {
  formField: FormField
  wizardField?: WizardFormField
  onFieldChange: (formField: WizardFormField) => void
}

export const FormFieldInput = ({ formField, wizardField, onFieldChange }: FormFieldProps) => {
  const [error, setError] = useState<string>()
  const intl = useTranslation()

  function updateFieldValue(value: string | number) {
    console.log('value', value)
    const formFieldValue: WizardFormField = {
      fieldId: formField.id,
      type: formField.type,
      label: formField.label,
      value,
    }

    if (formField.required && (value === '' || value === undefined)) {
      setError(
        intl({
          id: 'canNotBeEmpty',
        })
      )
    } else {
      setError(undefined)
    }

    onFieldChange(formFieldValue)
  }

  const updateFieldValues = (values: string[]) => {
    const fieldValue: WizardFormField = {
      fieldId: formField.id,
      type: formField.type,
      label: formField.label,
      multiValue: values,
    }

    onFieldChange(fieldValue)
  }

  return (
    <>
      <div>
        {formField?.type === 'number' && (
          <>
            <Input
              label={formField.label}
              name={formField.type}
              defaultValue={wizardField?.value}
              error={error}
              type='number'
              required={formField?.required}
              onChange={number => updateFieldValue(number)}
              placeholder={formField.label}
            />
          </>
        )}

        {formField?.type === 'text' && (
          <>
            <Input
              label={formField.label}
              name={formField.type}
              defaultValue={wizardField?.value}
              error={error}
              required={formField?.required}
              type='text'
              onChange={text => updateFieldValue(text)}
              placeholder={formField.label}
            />
          </>
        )}

        {formField?.type === 'radio' && (
          <div className='mb-5'>
            <RadioGroup
              defaultValue={wizardField?.value}
              onChange={radio => updateFieldValue(radio)}
            >
              {formField.label && (
                <RadioGroup.Label>
                  <legend className='text-base font-semibold text-gray-700 dark:text-gray-100 mb-2'>
                    {formField.label} {formField.required && '*'}
                  </legend>
                </RadioGroup.Label>
              )}
              <div className='space-y-2'>
                {formField.options?.map(option => (
                  <SelectablePaper
                    key={option.id}
                    name={option.value}
                    id={option.id}
                    value={option.value}
                    onClick={() => {}}
                  />
                  // <RadioGroup.Option
                  //   key={option.id}
                  //   value={option.value}
                  //   className={({ active }) =>
                  //     `${active ? '' : ''}
                  // relative flex cursor-pointer border border-solid border-custom-default rounded-lg px-5 py-4 focus:outline-none border border-solid`
                  //   }
                  // >
                  //   {({ checked }) => (
                  //     <>
                  //       <div className='flex w-full items-center justify-start'>
                  //         <div className='mr-2'>
                  //           <RadioCheck checked={checked} />
                  //         </div>
                  //         <div className='flex items-center'>
                  //           <div className='text-sm'>
                  //             <RadioGroup.Label
                  //               as='p'
                  //               className={`font-medium text-gray-700 dark:text-gray-100`}
                  //             >
                  //               {option.value}
                  //             </RadioGroup.Label>
                  //             <RadioGroup.Description
                  //               as='span'
                  //               className={`inline ${checked ? 'text-sky-100' : 'text-gray-500'}`}
                  //             ></RadioGroup.Description>
                  //           </div>
                  //         </div>
                  //       </div>
                  //     </>
                  //   )}
                  // </RadioGroup.Option>
                ))}
              </div>
            </RadioGroup>
          </div>
        )}

        {formField?.type === 'checkbox' && (
          <div>
            <CheckboxGroup
              label={formField.label}
              options={formField.options || []}
              required={formField?.required}
              values={wizardField?.multiValue}
              onCheckboxChange={updateFieldValues}
            />
          </div>
        )}
        {formField?.type === 'toggle' && (
          <div>
            <fieldset>
              <div className='space-y-2 mb-5'>
                <CheckboxOption
                  id={formField.id}
                  isChecked={wizardField?.value === TOGGLE_CHECKED}
                  value={formField?.label || ''}
                  onChange={e => updateFieldValue(e.target.checked ? TOGGLE_CHECKED : '')}
                />
              </div>
            </fieldset>
          </div>
        )}
        {formField?.type === 'info' && (
          <RichTextParse str={formField.defaultValue} className='text-sm' />
        )}
      </div>
    </>
  )
}
