import { Appointment, Place } from '@2meters/types'
import { CompressedTicketIcon } from 'assets/icons'
import { useQueues } from 'store/useQueues'
import { useVisitorLocale } from 'store/useVisitorLocale'
import FormattedMessage from 'utils/FormattedMessage'
import { isWorkingTime } from 'utils/isWorkingTime'
import { nextWorkingDay } from 'utils/nextWorkingDay'
import { timePeriodInText } from 'utils/timez'
import { QrCode } from './QrCode'
import { useIntl } from 'react-intl'

interface TabDetailsProps {
  app: Appointment | undefined
  place: Place
}

export const TabDetails = ({ app, place }: TabDetailsProps) => {
  const { queues } = useQueues()
  const intl = useIntl()
  const { visitorLocale } = useVisitorLocale()

  const isQueueWorking = place && isWorkingTime(place?.openingHours, place.timezone)
  const isNextWorkingDay = place && nextWorkingDay(place?.openingHours, intl.locale)
  const currentQueue = queues.find(queue => queue.id === app?.queueId)

  const waitingTime = timePeriodInText({
    durationSec: app?.estimatedWaitingTimeSec,
    locale: visitorLocale,
  })

  return (
    <>
      <div className='grid grid-cols-2 gap-2 mt-4 px-4'>
        <div className=''>
          <span className='text-xs text-gray-700'>
            <FormattedMessage id='visit' />
          </span>
          <div className='flex items-center'>
            <CompressedTicketIcon className='w-5 h-5 stroke-gray-600 mr-1' />
            <p className='text-lg font-bold text-gray-600'>{app?.tag}</p>
          </div>
        </div>

        {(app?.contactData?.firstName || app?.contactData?.lastName) && (
          <div className=''>
            <span className='text-xs text-gray-700'>
              <FormattedMessage id='name' />
            </span>
            <div className='flex items-center'>
              <p className='text-sm font-bold text-gray-700 mt-1'>
                {`${app?.contactData?.firstName} ${app?.contactData?.lastName}`}
              </p>
            </div>
          </div>
        )}

        {app?.positionInQueue !== undefined && (
          <div className=''>
            <span className='text-xs text-gray-700'>
              <FormattedMessage id='beforeYou' />
            </span>
            <div className='flex items-center'>
              <p className='text-sm font-bold text-gray-700 mt-1'>
                {app?.positionInQueue} <FormattedMessage id='people' />
              </p>
            </div>
          </div>
        )}

        {currentQueue?.isOnlyInOpeningHours && !isQueueWorking && (
          <div>
            <p className='text-xs font-medium text-gray-700 mt-1'>
              <FormattedMessage id='placedClosed' /> <br />
              <FormattedMessage id='openedOn' /> {isNextWorkingDay?.day} {isNextWorkingDay?.date} at{' '}
              {isNextWorkingDay?.openingTime}
            </p>
          </div>
        )}

        {/* {isQueueWorking && currentQueue?.estimatedWaitingTimeMode === 'constant' &&
          currentQueue?.estimatedWaitingTimeConstantMinutes &&
          currentQueue?.estimatedWaitingTimeConstantMinutes !== 0 && (
            <div>
              <span className='text-xs text-gray-700'>
                <FormattedMessage id='waitingTime' />
              </span>
              <p className='text-sm font-bold text-gray-700 mt-1'>
                {peopleBefore > 0
                  ? (peopleBefore - 1) * currentQueue?.estimatedWaitingTimeConstantMinutes
                  : currentQueue?.estimatedWaitingTimeConstantMinutes}{' '}
                <FormattedMessage id='min' />
              </p>
            </div>
          )} */}
        {waitingTime && (
          <div>
            <span className='text-xs text-gray-700'>
              <FormattedMessage id='waitingTime' />
            </span>
            <p className='text-sm font-bold text-gray-700 mt-1'>{waitingTime}</p>
          </div>
        )}

        {app?.counter && (
          <div className=''>
            <span className='text-xs text-gray-600'>
              <FormattedMessage id='counter' />
            </span>
            <p className='text-lg font-bold text-gray-600'>{app.counter}</p>
          </div>
        )}

        {app?.contactData?.numberOfVisitors && (
          <div>
            <span className='text-xs text-gray-600'>
              <FormattedMessage id='numberOfVisitors' />
            </span>
            <p className='text-sm font-semibold text-gray-700 mt-1'>
              {app.contactData.numberOfVisitors}
            </p>
          </div>
        )}
      </div>

      {app?.id && place?.id && (
        <div className='flex items-center justify-center'>
          <div className='flex items-center justify-center w-[136px] h-[136px] bg-slate-200 mt-5'>
            <QrCode placeId={place.id} appId={app?.id} />
          </div>
        </div>
      )}
    </>
  )
}
