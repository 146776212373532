import { OpeningHours, WorkDay, WorkingDayTime } from '@2meters/types'
import { DateTime, Interval } from 'luxon'

export const isWorkingTime = (
  openingHours: OpeningHours,
  timeZone: string = DateTime.now().zone.name
): boolean => {
  const now = DateTime.now().setZone(timeZone)
  const dayOfWeek = now.toFormat('ccc').toUpperCase().slice(0, 2) as keyof OpeningHours

  const dayInfo: WorkDay = openingHours[dayOfWeek]

  if (!dayInfo || !dayInfo.open || !dayInfo.workingTime) {
    return false
  }

  return dayInfo.workingTime.some(({ openingTime, closingTime }: WorkingDayTime) => {
    const opening = DateTime.fromISO(openingTime!, { zone: timeZone })
    const closing = DateTime.fromISO(closingTime!, { zone: timeZone })
    const interval = Interval.fromDateTimes(opening, closing)

    return interval.contains(now)
  })
}
