import { RadioGroup } from '@headlessui/react'
import { TicketIcon } from 'assets/icons'
import { RadioCheck } from 'views/BookPage/ui'
import { PropsWithChildren } from 'react'
import FormattedMessage from 'utils/FormattedMessage'

type Props = {
  disabled?: boolean
  value: string
  title: string
}

export const SelectablePanel = ({ children, disabled, title, value }: PropsWithChildren<Props>) => {
  return (
    <RadioGroup.Option
      value={value}
      disabled={disabled}
      className={({ checked, disabled }) =>
        `border-gray-300 text-gray-900
        ${checked && 'dark:text-white border-gray-400'}
         ${
           disabled
             ? 'text-gray-500 dark:text-gray-100 border-gray-300 dark:border-gray-500'
             : 'hover:bg-custom-element_hover active:bg-custom-element_active hover:border-gray-400 focus:border-gray-400 dark:hover:border-gray-300 dark:border-gray-400'
         }
               relative flex cursor-pointer rounded-lg border border-solid`
      }
    >
      {({ checked, disabled }) => (
        <div
          className={`flex flex-col w-full divide-y rounded-md divide-solid ${
            checked
              ? 'divide-gray-400 dark:divide-gray-400'
              : 'divide-gray-300 dark:divide-gray-400'
          }
            ${disabled && 'divide-gray-300 dark:divide-gray-500'}
          }`}
        >
          <div
            className={`flex w-full px-5 py-4 rounded-t-md opacity ${
              checked && 'bg-custom-element_active'
            }`}
          >
            <div className='flex w-full'>
              <div className='mr-3 items-center'>
                <TicketIcon
                  className={`w-6 h-6 ${
                    disabled
                      ? 'stroke-gray-500 dark:stroke-gray-200'
                      : 'stroke-gray-900 dark:stroke-gray-100'
                  }
                `}
                />
              </div>
              <div className='flex items-center'>
                <div className='text-sm'>
                  <RadioGroup.Label
                    as='p'
                    className={`font-medium ${
                      disabled ? 'text-gray-500' : 'text-gray-900 dark:text-gray-100'
                    }`}
                  >
                    <FormattedMessage id={title} />
                  </RadioGroup.Label>
                </div>
              </div>
            </div>

            <div className='mt-[2px]'>
              <RadioCheck checked={checked} />
            </div>
          </div>
          <div
            className={`flex flex-col justify-center h-full items-center rounded-b-md pt-3 bg-custom-bg`}
          >
            {children}
          </div>
        </div>
      )}
    </RadioGroup.Option>
  )
}
